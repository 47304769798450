.minutes_bigger_container, .hours_bigger_container, .seconds_bigger_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.seconds_bigger {
    background: #4f0000;
    height: 45%;
    left: 49.5%;
    position: absolute;
    top: 14%;
    transform-origin: 50% 80%;
    width: 1%;
    z-index: 8;
}

.clock_bigger {
    border-style: solid;
    border-width: 5px;
    border-color: #1ab394;
    border-radius: 50%;
    background: #fff url(/client/dist/58e31ed73845dbe7afe52110d5b71473.gif) no-repeat center;
    background-size: 90%;
    position: relative;
    height: 160px;
    width: 160px;
}

.hours_bigger {
    background: #000;
    height: 20%;
    left: 48.75%;
    position: absolute;
    top: 30%;
    transform-origin: 50% 100%;
    width: 2.5%;
}

.minutes_bigger {
    background: #000;
    height: 40%;
    left: 49%;
    position: absolute;
    top: 10%;
    transform-origin: 50% 100%;
    width: 2%;
}




.clock_bigger.show {
    opacity: 1;
    transform: translateY(-50%);
    transition: all 2.5s 0.5s cubic-bezier(.12, 1.03, .34, 1);
}