.minutes-container, .hours-container, .seconds-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.seconds {
    background: #4f0000;
    height: 45%;
    left: 49.5%;
    position: absolute;
    top: 14%;
    transform-origin: 50% 80%;
    width: 1%;
    z-index: 8;
}

.clock {
    border-style: solid;
    border-width: 2px;
    border-color: #1ab394;
    border-radius: 50%;
    background: #fff url(/client/dist/58e31ed73845dbe7afe52110d5b71473.gif) no-repeat center;
    background-size: 90%;
    position: relative;
    height: 60px;
    width: 60px;
}

@keyframes rotate {
    100% { transform: rotateZ(360deg); }
}

.hours-container { animation: rotate 43200s infinite linear; }

.minutes-container { animation: rotate 3600s infinite linear; }

.seconds-container { animation: rotate 60s infinite linear; }

.hours {
    background: #000;
    height: 20%;
    left: 48.75%;
    position: absolute;
    top: 30%;
    transform-origin: 50% 100%;
    width: 2.5%;
}

.minutes {
    background: #000;
    height: 40%;
    left: 49%;
    position: absolute;
    top: 10%;
    transform-origin: 50% 100%;
    width: 2%;
}

.clock.simple:after {
    background: #000;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5%;
    height: 5%;
    z-index: 10;
}

.clock.show {
    opacity: 1;
    transform: translateY(-50%);
    transition: all 2.5s 0.5s cubic-bezier(.12, 1.03, .34, 1);
}