.tag_primary {
    background-color: #34495e !important;
    border-color: #34495e !important;
    color: white !important;
    padding: 5px !important;
}

.tag_success {
    background-color: #1ab394 !important;
    border-color: #1ab394 !important;
    color: white !important;
    padding: 5px !important;
}

.tag_warning {
    background-color: #ffb606 !important;
    border-color: #ffb606 !important;
    color: white !important;
    padding: 5px !important;
}

.tag_default {
    background-color: #777 !important;
    border-color: #777 !important;
    color: white !important;
    padding: 5px !important;
}


/* PIN BOARD */

ul.notes li,
ul.tag-list li { list-style: none; }

ul.notes li h4 {
    margin-top: 20px;
    font-size: 16px;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 140px;
    width: 140px;
    padding: 1em;
    position: relative;
}

ul.notes li div small {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
}

ul.notes li div a {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: inherit;
}

ul.notes li {
    margin: 10px 40px 50px 0;
    float: left;
}

ul.notes li div p { font-size: 12px; }

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 140px;
    width: 140px;
    padding: 1em;
    /* Firefox */
    -moz-box-shadow: 5px 5px 2px #212121;
    /* Safari+Chrome */
    -webkit-box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
    /* Opera */
    box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
}

ul.notes li div {
    -webkit-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
}

ul.notes li:nth-child(even) div {
    -o-transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    position: relative;
    top: 5px;
}

ul.notes li:nth-child(3n) div {
    -o-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    position: relative;
    top: -5px;
}

ul.notes li:nth-child(5n) div {
    -o-transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    position: relative;
    top: -10px;
}

ul.notes li div:hover,
ul.notes li div:focus {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    position: relative;
    z-index: 5;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 210px;
    width: 210px;
    padding: 1em;
    -moz-box-shadow: 5px 5px 7px #212121;
    -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    -moz-transition: -moz-transform 0.15s linear;
    -o-transition: -o-transform 0.15s linear;
    -webkit-transition: -webkit-transform 0.15s linear;
}

/* END OF PIN BOARD */


.taskA {
    font-size: 25px !important;
    font-stretch: extra-condensed !important;
    color: #1ab394;
    font-weight: 100 !important;
}

.taskB {
    font-size: 16px !important;
    font-stretch: extra-condensed !important;
    color: #1ab394;
    font-weight: 100 !important;
}

.taskC {
    font-stretch: extra-condensed !important;
    font-weight: 100 !important;
}

.taskD {
    font-size: smaller !important;
    font-stretch: extra-condensed !important;
    font-weight: 100 !important;
}

.taskE {
    font-size: smaller !important;
    font-stretch: extra-condensed !important;
    font-weight: 100 !important;
    color: #DDDDDD !important;
}

.done {
    color: #DDDDDD !important;
    text-decoration: line-through;
}

/* TOOGLE SWICH */

.onoffswitch {
    position: relative;
    width: 64px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox { display: none; }

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #1ab394;
    border-radius: 2px;
}

.onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #1ab394;
    color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #FFFFFF;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    width: 20px;
    margin: 0;
    background: #FFFFFF;
    border: 2px solid #1ab394;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 44px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner { margin-left: 0; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch { right: 0; }

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:before { background-color: #919191; }

.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch { border-color: #919191; } 


/* TOOGLE SWICH */

.onoffswitch {
    position: relative;
    width: 64px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox { display: none; }

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #1ab394;
    border-radius: 2px;
}

.onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #1ab394;
    color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #FFFFFF;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    width: 20px;
    margin: 0;
    background: #FFFFFF;
    border: 2px solid #1ab394;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 44px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner { margin-left: 0; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch { right: 0; }

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:before { background-color: #919191; }

.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch { border-color: #919191; }