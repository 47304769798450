.md-chips { font-size: small !important; }

.md-chips md-chip {
    padding: 0 0 0 0 !important;
    padding-right: 30px !important;
}

.md-chip-remove md-icon {
    min-height: 0px !important;
    min-width: 0px !important;
    height: 12px !important;
    width: 12px !important;
}

.md-icon {
    min-height: 0px !important;
    min-width: 0px !important;
    height: 12px !important;
    width: 12px !important;
}

/*.md-select-menu-container {*/

/*    z-index: 9999 !important;*/

/*}*/

.md-select-menu-container { z-index: 9999; }

md-backdrop.md-select-backdrop { z-index: 9998; }

.label { font-family: 'Roboto' !important; }

body {
    font-family: "Roboto" !important;
    font-weight: 300;
    font-size: 14px;
    background-color: #fafafa !important;
    height: 100%;
}