.progress-bar-grey { background-color: #777 !important; }

.tag2 {
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.tag {
    color: #808080;
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

label {
    padding-top: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.header_label {
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-weight: 100;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
}

.letter_spacing { letter-spacing: 1px; }

.header_menu_label {
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 0px !important;
    padding: 0px;
}

.drop-down-menu {
    float: none;
    margin-top: 0;
    position: relative;
    padding: 0;
}

.panel-dropdown-menu { color: grey; }

.md-chip-content { text-transform: uppercase !important; }

.hbadgegreen {
    background-color: #1ab394;
    color: white;
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-weight: 300;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px;
}

.hpanel.hprimary .panel-body { border-top: 2px solid #34495e; }

.text-white { color: white; }

.text-primary { color: #34495e; }

.hbadgeprimary {
    background-color: #34495e;
    color: white;
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-weight: 300;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px;
}

.progress-bar-horizontal {
    float: left;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.animate-show,
.animate-hide {
    -webkit-transition: all linear 1s;
    -moz-transition: all linear 1s;
    -ms-transition: all linear 1s;
    -o-transition: all linear 1s;
    transition: all linear 1s;
}

.animate-show.ng-hide-remove,
.animate-hide.ng-hide-add.ng-hide-add-active {
    opacity: 0;
    display: block !important;
}

.animate-hide.ng-hide-add,
.animate-show.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    display: block !important;
}